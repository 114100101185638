import React from "react"
import { Link } from "gatsby"
import GoogleMapReact from "google-map-react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <header
      id="home"
      className="jumbotron bg-image"
      style={{
        backgroundImage: 'url("/images/main.jpg")',
        backgroundPosition: "center",
      }}
    >
      <div className="container text-center">
        <p>진정한 휴식</p>
        <h1>다누리 펜션</h1>
      </div>
    </header>

    <section className="gray-bg cta">
      <div className="container">
        <div className="row">
          <div className="col-md-8 cta-message">
            <p>
              <strong>'다누리펜션'에서 진정한 휴식을 느껴보세요.</strong>
              <br />
              가까운 거리에 녹차밭과 다원, 해수욕장이 있습니다.
            </p>
          </div>
          <div className="col-md-4 cta-button">
            <p className="text-center">
              <a className="btn btn-qubico" href="/reservation/reserve">
                <i className="fa fa-shopping-cart"></i>예약하기
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      id="about"
      className="color-bg light-typo padding-top-bottom bg-image"
      style={{
        backgroundImage: `url(/images/main3.jpg)`,
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <img
              className="img-responsive image-width-border"
              src="/images/about.jpg"
              alt=""
              style={{ opacity: 0.9, marginBottom: "20px" }}
            />
          </div>

          <div className="col-sm-5 col-sm-offset-1">
            <p>
              반복되는 일상, 쌓여가는 피로,
              <br /> 바쁜 하루하루 속에 지쳐버린 몸과 마음.
            </p>
            <p>오늘 하루 쯤은 모든 걱정을 잊고 자연의 품 안에 안기세요.</p>
            <p>
              가까운 거리에 있는 다원과 차 박물관, 제암산 자연휴양림,
              메타쉐쿼이아 가로수길을 거닐며
            </p>
            <p>자연이 주는 에너지를 가득 담아가시는건 어떠신가요.</p>
            <p>
              다누리는 청정 자연을 배경삼아 드넓은 녹차밭 인근에 조용히 자리잡고
              있습니다.
            </p>
            <p>어서 오셔서 휴식의 진정한 의미를 느껴보세요.</p>
            <h2>다누리는 휴식입니다.</h2>
            <p>Welcome to Danoori Pension</p>

            <a className="btn btn-qubico white scrollto" href="/#spaces">
              <i className="fa fa-arrow-down"></i>더 자세히 알아보기
            </a>
          </div>
        </div>
      </div>
    </section>

    <section id="spaces" className="white-bg padding-top-bottom">
      <div className="container">
        <h1 className="section-title">객실</h1>
        <p className="section-description">
          아늑하고 깨끗한 방에서 편안한 휴식을 누리세요.
        </p>

        <div className="row services">
          <div className="col-sm-4 item text-center scrollimation fade-right d2">
            <div
              className="icon"
              style={{
                backgroundImage: `url(/images/rooms/flower/thumbnail_flower.jpg)`,
              }}
            ></div>
            <h3>꽃이슬</h3>
            <p>
              육각형의 돔 형식으로 지어진 독특한 침실구조, <br />
              창밖에는 보성의 청정자연이 한눈에 들어오는 1층 테라스를 이용하실
              수 있는 예쁜 방이예요.
            </p>
            <a className="btn" href="/rooms/flower">
              더보기 <i className="fa fa-external-link"></i>
            </a>
          </div>

          <div className="col-sm-4 item text-center scrollimation fade-left">
            <div
              className="icon"
              style={{
                backgroundImage: `url(/images/rooms/mountain/thumbnail_mountain.jpg)`,
              }}
            ></div>
            <h3>산</h3>
            <p>
              2층에 위치해 따뜻한 햇살이 드는 방으로, <br />
              보성의 따뜻하고 넉넉한 풍경을 맘껏 감상하실 수 있는 테라스가 있는
              특별한 방이예요.
            </p>
            <a className="btn" href="/rooms/mountain">
              더보기 <i className="fa fa-external-link"></i>
            </a>
          </div>

          <div className="col-sm-4 item text-center scrollimation fade-left d2">
            <div
              className="icon"
              style={{
                backgroundImage: `url(/images/rooms/star/thumbnail_star.jpg)`,
              }}
            ></div>
            <h3>별</h3>
            <p>
              {" "}
              침실 주변 사방으로 나 있는 창문으로 햇빛이 가득 들어오는, <br />
              가족끼리 단란한 시간을 보내기에도, 특별한 날 연인끼리 분위기를
              잡기에도 좋은 방입니다.
            </p>
            <a className="btn" href="/rooms/star">
              더보기 <i className="fa fa-external-link"></i>
            </a>
          </div>
        </div>

        <h1 className="subsection-title">편의시설</h1>
        <p className="subsection-description">
          더 편하고 즐겁게 쉬다 가실 수 있도록, 다양한 편의시설을 제공합니다.
        </p>

        <div className="process-box scrollimation">
          <ul className="process-list">
            <li className="colored">
              <p
                className="icon"
                style={{
                  backgroundImage: `url(/images/spaces/facility/barbecue/facility_barbecue_02.jpg)`,
                }}
              ></p>
              <h4>실내 바베큐장</h4>
              <a className="btn" href="/spaces/barbecue">
                더보기 <i className="fa fa-external-link"></i>
              </a>
            </li>
            <li className="colored">
              <p
                className="icon"
                style={{
                  backgroundImage: `url(/images/spaces/facility/bench/facility_bench_03.png)`,
                }}
              ></p>
              <h4>야외 테이블</h4>
              <a className="btn" href="/spaces/bench">
                더보기 <i className="fa fa-external-link"></i>
              </a>
            </li>
            <li className="colored">
              <p
                className="icon"
                style={{
                  backgroundImage: `url(/images/spaces/facility/etc/facility_microoven_01.jpg)`,
                }}
              ></p>
              <h4>기타 공동 시설</h4>
              <a className="btn" href="/spaces/common">
                더보기 <i className="fa fa-external-link"></i>
              </a>
            </li>
          </ul>

          <div className="process-line"></div>
        </div>

        <p className="text-center">
          <a className="btn btn-qubico" href="/reservation/reserve">
            예약하기
          </a>
        </p>
      </div>
    </section>

    <section className="gray-bg padding-top">
      <div className="container">
        <h1 className="section-title">다누리 전경</h1>
        <p className="section-description">
          사계절 아름다운 다누리의 전경을 만끽하세요.
        </p>

        <p className="text-center">
          <a className="btn btn-qubico" href="/spaces/landscape">
            더 보기
          </a>
        </p>

        <div className="ipad-frame scrollimation fade-up">
          <img
            className="img-responsive img-center"
            src="/images/ipad-landscape.png"
            alt=""
          />

          <div className="ipad-screen ipad-slider flexslider">
            <ul className="slides">
              <li>
                <img
                  className="img-responsive"
                  src="/images/spaces/landscape/landscape_05.jpg"
                  alt=""
                />
              </li>
              <li>
                <img
                  className="img-responsive"
                  src="/images/spaces/landscape/night_01.jpg"
                  alt=""
                />
              </li>
              <li>
                <img
                  className="img-responsive"
                  src="/images/spaces/landscape/winter_03.jpg"
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section id="map" className="white-bg padding-top-bottom">
      <div className="container">
        <h1 className="section-title">찾아오시는 길</h1>
        <p className="section-description">
          대중교통으로도 자가용으로도 편하게 오실 수 있습니다.
        </p>

        <div className="row">
          <div className="col-sm-6">
            <div
              id="danooriLocation"
              style={{ width: "100%", height: "400px" }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_API_KEY,
                }}
                defaultCenter={{ lat: 34.723132, lng: 127.092973 }}
                defaultZoom={15}
              ></GoogleMapReact>
            </div>
          </div>
          <br />

          <div className="col-sm-5 col-sm-offset-1">
            <div>
              <h2 className="text-center">
                <i className="fa fa-car"></i>
                <b>자가용</b>으로 오실때
              </h2>
              <p>
                <b>오시는 경로</b>: 호남고속도로 &gt; 송과사 IC &gt; 18번국도
                &gt; 보성군청방면 &gt; 보성군 통과 &gt; 18번국도 &gt;
                한국차박물관 방면 &gt; 다누리펜션
              </p>
              <p>
                <b>네비게이션 주소</b>: 전남 보성군 보성읍 녹차로 890-28
              </p>
            </div>
            <br />
            <div>
              <h2 className="text-center">
                <i className="fa fa-bus"></i>
                <b>대중교통</b>으로 오실때
              </h2>
              <p>
                <b>보성시외버스터미널</b>에서 '녹차밭 방면'으로 가는 군내버스를
                타시고
                <b>삼산마을에서 하차</b>하세요.
                <br />
                (40분간격 운행, 10분 소요)
              </p>
              <p>
                <b>네비게이션 주소</b>: 전남 보성군 보성읍 녹차로 890-28
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
